import { get, post, put, del, getTotal, upload } from "./api";
import uniqueId from "lodash/uniqueId";

export async function createWatch(lotId) {
  const { data } = await post("watch", { lotId });
  return data;
}

export async function removeWatch(watchId) {
  const { data } = await del(`watch/${watchId}`);
  return data;
}

export async function getWatchlistCount(filters) {
  const { data } = await get("application/community/watch/count", filters);
  return data;
}

export async function getWatches(filters, range, sort) {
  const res = await get("watch", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getNotes(filters, range, sort) {
  const preventCache = uniqueId("notes");
  const res = await get(`note?request.preventCache=${preventCache}`, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function createNote(note) {
  const { data } = await post("note", note);
  return data;
}

export async function updateNote(note) {
  const { data } = await put("note", note);
  return data;
}

export async function deleteNote(note) {
  return del(`note/${note.id}`);
}

export async function createAMSNote(note) {
  const { data } = await post("ams/note", note);
  return data;
}

export async function deleteAMSNote(noteId) {
  await del(`ams/note/${noteId}`);
}

export async function getLot(lotId) {
  const { data } = await get(`lot/${lotId}`);
  return data;
}

export async function getLots(filters, range, sort) {
  const preventCache = uniqueId("lots");
  const res = await get(`lot?request.preventCache=${preventCache}`, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function uploadInventory(headers, file, saleId, schemaId) {
  const { data } = await upload(`sale/${saleId}/lots/${schemaId}`, headers, file);
  return data;
}

export async function deleteInventory(saleId) {
  const { data } = await del(`sale/${saleId}/lots`);
  return data;
}

export function createLot(lot) {
  return post("lot", lot);
}

export async function updateLot(lot) {
  const { data } = await put(`lot/${lot.id}`, lot);
  return data;
}

export async function getAsset(assetId) {
  const { data } = await get(`asset/${assetId}`);
  return data;
}

export async function getAssets(category, filters, range, sort) {
  const preventCache = uniqueId("assets");

  let url = category
    ? `application/community/asset/${category}?request.preventCache=${preventCache}`
    : `application/community/asset?request.preventCache=${preventCache}`;

  const res = await get(url, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function updateAsset(asset) {
  const { data } = await put(`asset/${asset.id}`, asset);
  return data;
}

export async function getCondition(filters, range, sort) {
  const res = await get("condition", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getDisclosures(filters, range, sort) {
  const res = await get("disclosure", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function createDisclosure(disclosure) {
  await post("disclosure", disclosure);
}

export async function deleteDisclosure(disclosureId) {
  await del(`disclosure/${disclosureId}`);
}

export async function updateDisclosure(disclosure) {
  await put(`disclosure/${disclosure?.id}`, disclosure);
}

export async function search(filters, range, sort) {
  const res = await get("application/community/search", filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function searchLite(filters, range, sort) {
  const res = await get("application/community/search-lite", filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function searchPostSale(filters, range, sort) {
  const res = await get("application/community/postSale", filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function getLocations(filters, range, sort) {
  const res = await get("location", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getMarketReportStats(filters) {
  const { data } = await get("application/community/marketReport/stats", filters);
  return data;
}

export async function getMarketReport(filters, range, sort) {
  const { data } = await get("application/community/marketReport", filters, range, sort);
  return data;
}

export async function getSearchOption(searchOption, filters) {
  const { data } = await get(`application/community/searchOption/${searchOption}`, filters);

  return data;
}

export async function withdraw(withdraw) {
  await put(`withdraw?request.preventCache=${uniqueId("withdraw")}`, withdraw);
}

export async function out(out) {
  await put(`out?request.preventCache=${uniqueId("out")}`, out);
}

export async function sell(sell) {
  await put("sell", sell);
}

export async function view(view) {
  await put(`view?request.preventCache=${uniqueId("view")}`, view);
}

export async function floorBidderName(payload) {
  await put(`floorBidderName?request.preventCache=${uniqueId("floorBidderName")}`, payload);
}

export async function getLinks(filters, range, sort) {
  const res = await get("link", filters, range, sort);

  return { data: res.data, total: getTotal(res) };
}
